<template>
  <div id="Login">
    <ul>
      <li>
        <p class="grey9">{{ username ? '请输入用户名': '' }}</p>
        <input v-model="username" type="text" placeholder="请输入用户名">
      </li>
      <li>
        <p class="grey9">{{ password ? '请输入登录密码': '' }}</p>
        <input v-model="password" type="password" placeholder="请输入登录密码">
      </li>
      <li>
        <p class="grey9">{{ captcha ? '请输入验证码': '' }}</p>
        <div style="display: flex; align-items: center;">
          <input v-model="captcha" type="text" placeholder="请输入验证码" style="width: 300px;">
          <img :src="captchaImage" alt="验证码" @click="refreshCaptcha" style="margin-left: 20px; cursor: pointer;">
        </div>
      </li>
    </ul>
    <div
      class="button"
      :class="{'disabled': isDisabled}"
      @click="submitLogin"
    >登录</div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'Login',
  data() {
    return {
      username: '', // 用户名
      password: '', // 密码
      captcha: '',  // 验证码输入值
      captchaImage: '', // 验证码图片 URL
    };
  },
  computed: {
    isDisabled() {
      return !this.username || !this.password || !this.captcha;
    }
  },
  created() {
    this.refreshCaptcha(); // 页面加载时刷新验证码
  },
  methods: {
    ...mapActions({
      'login': 'userInfo/login'
    }),
    refreshCaptcha() {
      const baseUrl = process.env.VUE_APP_BASE_URL;
      this.captchaImage = `${baseUrl}/site/captcha?_t=${new Date().getTime()}`;
    },
    submitLogin() {
      if (this.isDisabled) return;

      this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 0
      });

      this.login({
        username: this.username,
        password: this.password,
        captcha: this.captcha
      })
      .then(res => {
        this.$router.push({ name: 'proList' });
      })
      .catch(err => {
        if (err.message === '验证码错误') {
          this.$toast.fail('验证码错误，请重新输入');
          this.captcha = ''; // 清空验证码输入框
          this.refreshCaptcha(); // 刷新验证码
        } else {
          this.$toast.fail('登录失败，请检查用户名和密码');
        }
      })
      .finally(() => {
        this.$toast.clear();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
#Login {
  padding: 448px 60px 0;
  height: 100%;
  box-sizing: border-box;
  background: url("../assets/images/login_backgroud.png") no-repeat center center;
  background-size: 100% 100%;
  ul {
    li {
      height: 134px;
      padding: 16px 42px 0;
      border-bottom: 1px rgba($color-primary, .3) solid;
      box-sizing: border-box;
      p {
        font-size: 24px;
        height: 32px;
        line-height: 32px;
      }
      input {
        height: 40px;
        line-height: 40px;
        padding: 20px 0 24px;
        width: 544px;
        background: transparent;
      }
      img {
        width: 120px;
        height: 40px;
        cursor: pointer;
      }
    }
  }
  .button {
    width: 320px;
    height: 96px;
    line-height: 96px;
    background: linear-gradient(270deg, $color-primary-light 0%, $color-primary 100%);
    border-radius: 53px;
    font-size: 32px;
    text-align: center;
    color: #fff;
    float: right;
    margin-top: 98px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &.disabled {
      background: rgba($color-primary, .3);
      cursor: not-allowed;
      opacity: 0.6;
    }
  }
}
</style>